<template>
  <div class="col">
    <div class="row">
      <div class="col-xl-4 col-md-6" v-if="hasPermission('access_questions')">
        <router-link to="/questions">
          <stats-card
            :title="`${localization('LCI Questions')}`"
            style="min-height: 160px; text-transform: uppercase"
          >
            <div slot="header" class="icon-warning">
              <i class="fa fa-question-circle text-info"></i>
            </div>
          </stats-card>
        </router-link>
      </div>
      <div class="col-xl-4 col-md-6" v-if="hasPermission('access_strategies')">
        <router-link to="/strategies">
          <stats-card
            :title="`${localization('Strategies')}`"
            style="min-height: 160px; text-transform: uppercase"
          >
            <div slot="header" class="icon-warning">
              <i class="fa fa-database text-info"></i>
            </div>
          </stats-card>
        </router-link>
      </div>
      <div class="col-xl-4 col-md-6" v-if="hasPermission('access_words')">
        <router-link to="/words">
          <stats-card
            :title="`${localization('Words')}`"
            style="min-height: 160px; text-transform: uppercase"
          >
            <div slot="header" class="icon-warning">
              <i class="fa fa-file-word-o text-info"></i>
            </div>
          </stats-card>
        </router-link>
      </div>
      <div class="col-xl-4 col-md-6" v-if="hasPermission('access_topics')">
        <router-link to="/topics">
          <stats-card
            :title="`${localization('Topics')}`"
            style="min-height: 160px; text-transform: uppercase"
          >
            <div slot="header" class="icon-warning">
              <i class="fa fa-list text-info"></i>
            </div>
          </stats-card>
        </router-link>
      </div>
      <div
        class="col-xl-4 col-md-6"
        v-if="!hasPermission('manage_institutions')"
      >
        <router-link to="/attributes">
          <stats-card
            :title="`${localization('Attributes')}`"
            style="min-height: 160px; text-transform: uppercase"
          >
            <div slot="header" class="icon-warning">
              <i class="fa fa-list text-info"></i>
            </div>
          </stats-card>
        </router-link>
      </div>
      <div class="col-xl-4 col-md-6" v-if="hasPermission('access_cms')">
        <router-link to="/cms">
          <stats-card
            :title="`${localization('Website CMS')}`"
            style="min-height: 160px; text-transform: uppercase"
          >
            <div slot="header" class="icon-warning">
              <i class="fa fa-internet-explorer text-info"></i>
            </div>
          </stats-card>
        </router-link>
      </div>
      <div class="col-xl-4 col-md-6" v-if="hasPermission('access_roles')">
        <router-link to="/roles">
          <stats-card
            :title="`${localization('Roles & Permissions')}`"
            style="min-height: 160px; text-transform: uppercase"
          >
            <div slot="header" class="icon-warning">
              <i class="fa fa-lock text-info"></i>
            </div>
          </stats-card>
        </router-link>
      </div>
      <div class="col-xl-4 col-md-6" v-if="hasPermission('access_logs')">
        <router-link to="/logs">
          <stats-card
            :title="`${localization('Logs')}`"
            style="min-height: 160px; text-transform: uppercase"
          >
            <div slot="header" class="icon-warning">
              <i class="fa fa-history text-info"></i>
            </div>
          </stats-card>
        </router-link>
      </div>
      <!-- //// Start Team Statements  -->
      <div
        class="col-xl-4 col-md-6"
        v-if="!hasPermission('manage_institutions')"
      >
        <router-link to="/team-statements">
          <stats-card
            title="Team Statements"
            style="min-height: 160px; text-transform: uppercase"
          >
            <div slot="header" class="icon-warning">
              <i class="fa fa-users text-info"></i>
            </div>
          </stats-card>
        </router-link>
      </div>
      <!--//// End Team Statements  -->
      <!-- //// Start  Statements Questions  -->
      <div
        class="col-xl-4 col-md-6"
        v-if="!hasPermission('manage_institutions')"
      >
        <router-link to="/statements-questions">
          <stats-card
            title="Team Statements Questions"
            style="min-height: 160px; text-transform: uppercase"
          >
            <div slot="header" class="icon-warning">
              <i class="fa fa-users text-info"></i>
            </div>
          </stats-card>
        </router-link>
      </div>
      <!--//// End  Statements Questions  -->
      <div
        class="col-xl-4 col-md-6"
        v-if="!hasPermission('manage_institutions')"
      >
        <router-link to="/languages">
          <stats-card
            :title="`${localization('Languages')}`"
            style="min-height: 160px; text-transform: uppercase"
          >
            <div slot="header" class="icon-warning">
              <i class="fa fa-language text-info"></i>
            </div>
          </stats-card>
        </router-link>
      </div>
      <!-- localization  -->
      <div
        class="col-xl-4 col-md-6"
        v-if="!hasPermission('manage_institutions')"
      >
        <router-link to="/localization">
          <stats-card
            :title="`${localization('localization')}`"
            style="min-height: 160px; text-transform: uppercase"
          >
            <div slot="header" class="icon-warning">
              <i class="fa fa-language text-info"></i>
            </div>
          </stats-card>
        </router-link>
      </div>
      <!-- Task Categories  -->
      <div class="col-xl-4 col-md-6">
        <router-link to="/task-categories">
          <stats-card
            :title="`${localization('Task Categories')}`"
            style="min-height: 160px; text-transform: uppercase"
          >
            <div slot="header" class="icon-warning">
              <i class="fa fa-tasks text-info"></i>
            </div>
          </stats-card>
        </router-link>
      </div>
      <!-- User Categories  -->
      <div class="col-xl-4 col-md-6">
        <router-link to="/user-categories">
          <stats-card
            :title="`${localization('User Categories')}`"
            style="min-height: 160px; text-transform: uppercase"
          >
            <div slot="header" class="icon-warning">
              <i class="fa fa-tasks text-info"></i>
            </div>
          </stats-card>
        </router-link>
      </div>
      <!-- career-statements  -->
      <div class="col-xl-4 col-md-6">
        <router-link to="/career-statements">
          <stats-card
            title="career statements"
            style="min-height: 160px; text-transform: uppercase"
          >
            <div slot="header" class="icon-warning">
              <i class="fa fa-briefcase"></i>
            </div>
          </stats-card>
        </router-link>
      </div>
      <!-- career-statements-questions  -->
      <div class="col-xl-4 col-md-6">
        <router-link to="/career-statements-questions">
          <stats-card
            title="career statements questions"
            style="min-height: 160px; text-transform: uppercase"
          >
            <div slot="header" class="icon-warning">
              <i class="fa fa-briefcase"></i>
            </div>
          </stats-card>
        </router-link>
      </div>
      <!-- career-strategies  -->
      <div class="col-xl-4 col-md-6">
        <router-link to="/career-strategies">
          <stats-card
            title="Career Strategies"
            style="min-height: 160px; text-transform: uppercase"
          >
            <div slot="header" class="icon-warning">
              <i class="fa fa-briefcase"></i>
            </div>
          </stats-card>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { StatsCard } from "../../components";
// import { StatsCard} from 'src/components/index'

export default {
  name: "index",
  components: {
    StatsCard
  }
};
</script>

<style scoped></style>
